import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { CallAPIUsingAxios } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import AlertWithTitle from '../components/Controls/AlertWithTitle';

const TemplateUpdDlg = (props) => {

    const { onClose, open, onChange } = props;
    const [invalidJSON, setInvalidJSON] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [successMsgTitle, setSuccessMsgTitle] = useState('');
  
    const [jsonUpload, setJsonUpload] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [templateName, setTemplateName] = useState(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleOk = async (event) => {
        event.preventDefault();
        setSuccessMsgTitle('');
        setSuccessMsg('');

        if (!invalidJSON) {

            try {

                setIsLoading(true);

                const apiUrl = new URL(
                    process.env.REACT_APP_IMPPORT_TEMPLATE_JSON,
                    process.env.REACT_APP_BASE_URL,
                );

                const imageFormData = new FormData();
                imageFormData.append("TemplateName", templateName);
                imageFormData.append("File", jsonUpload);

                //let response = await CallAPIUsingAxios({ url: apiUrl.href, method: 'POST', formData: imageFormData }, userId, sessionId);

                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: imageFormData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                let apiErrors = response["Errors"];


                if (apiErrors !== undefined && apiErrors.length > 0) {                  
                    setErrorMsg(apiErrors[0].Message);
                    return;
                }


                if (response !== null && response !== undefined) {

                    setSuccessMsgTitle("Template imported");
                    setSuccessMsg("Template created successfully. Template name: '" + props.enrmntName + "'.")
                    await WriteToActivityLog(ActivityLogEvent.Application, "Template created successfully. Template name: '" + props.enrmntName + "'.", userId, sessionId);

                    onChange();
                }
            }
            catch (err) {
                if (err.response.headers.aoberrorcode === 8 ||
                    err.response.headers.aoberrorcode === 29) {
                    setErrorMsg(err.response.headers.aobmessage);
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            finally {
                setIsLoading(false);
            }
        }
        else {
            setErrorMsg('Please select a valid json file.');
        }
        //else if (!jsonUrl) {
        //    setErrorMsg('Please select a valid json file.');
        //}
    };

    const onTemplateNameChange = (event) => {
        setTemplateName(event.target.value);

        if (jsonUpload && event.target.value !== null)
            setIsOkBtnEnabled(event.target.value.length > 0);
    }

    const onChangeFile = (event) => {
        const jsonFile = event.target.files[0];

        //const reader = new FileReader();

        setErrorMsg('');

        if (!jsonFile) {
            setErrorMsg("Please select an json file.");
            setInvalidJSON(true);
            return false;
        }

        if (!jsonFile.name.match(/\.(json)$/)) {
            setErrorMsg("Please select a valid json file.");
            setInvalidJSON(true);
            return false;
        }

        if (jsonFile.type !== 'application/json') {
            setErrorMsg("Please select a valid json file.");
            setInvalidJSON(true);
            return false;
        }

        setJsonUpload(jsonFile);
        setInvalidJSON(false);
        setIsOkBtnEnabled(templateName !== null && templateName.length > 0);
    }

    const loadScreen = async () => {

        try {

            setIsLoading(true);

            setErrorMsg('');
            setSuccessMsgTitle('');
            setSuccessMsg('');
            setInvalidJSON(false);
            setJsonUpload(null);
            setTemplateName('');
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        loadScreen();

    }, []);

    return (
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>
                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                        Import Template
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pl: 2, pr: 2, pb: 0.5 }}>
                        <AlertWithTitle Message={successMsg} variant="body3" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        <Grid container>
                            <Grid item md={11} sx={{}}>
                                <Typography sx={{ fontWeight: 400 }} variant="body1" color="text.secondary">
                                    You can import form template from a JSON file that contains template definition in JSON format. Please choose a template file to create a new template.
                                </Typography>
                            </Grid>
                            <Grid item md={10} sx={{ pt: 4, pb: 4 }}>
                                <label htmlFor="tempateName" className="form-label">Template Name*</label>
                                <input type="tempateName" id="tempateName" name="tempateName" autoComplete="off" placeholder="Enter template name here" className="form-control" onChange={onTemplateNameChange} required />
                                
                            </Grid>
                           
                            <Grid item md={11} sx={{ pt: 3, pb: 2 }}>
                                <FormControl variant="outlined">
                                    <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept="application/json" />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Button autoFocus onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', mr: 1, textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', textTransform: 'none' }}>
                        Upload
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default TemplateUpdDlg;
