import React from 'react';
import { useNavigate  } from "react-router-dom";

const BreadCumControl = (props) => {

    let history = useNavigate();
    const gotHomePath = (event) => {
        event.preventDefault();
        history.push({ pathname: '/DashBoard' });
    }

    return (
        <>
            <nav className="breadcrumb-nav p-s-2 p-t-2 p-b-2" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a onClick={gotHomePath}><i className="fa fa-home p-e-1"></i><span className="fw-bold">Home</span></a></li>
                    <li className="breadcrumb-item active" aria-current="page"><span className="fw-bold">{props.pathname}</span></li>
                </ol>
            </nav>
        </>
        );
}

export default BreadCumControl;