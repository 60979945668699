import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu';
import OverlaySpinner from './Controls/OverlaySpinner';
import { MainNavMenu } from './MainNavMenu';
import Footer from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Divider, Paper, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, bull, CardActions, Button } from '@mui/material';
import { CardMedia, CardHeader } from '@mui/material';
import { Search, EnvelopeOpen, ArchiveFill, ChevronDown, TrashFill, ThreeDotsVertical, ArrowClockwise, AlignCenter } from 'react-bootstrap-icons';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Avatar from '@mui/material/Avatar';
import { deepOrange, pink, blue, grey } from '@mui/material/colors';

import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertDialog } from '../global/AlertDialog';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import DescriptionIcon from '@mui/icons-material/Description';
import PublishIcon from '@mui/icons-material/Publish';
import { ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TemplateSortAction, AOBSystemProcess } from '../common/AOBEnum';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import AlertControl from './Controls/AlertControl';
import Container from '@mui/material/Container';
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import pwdimg from '../assets/images/23512.jpg';
import Image from 'material-ui-image'
import { IsError, IsInRole, GetLoggedInUserId } from '../global/APIUtils';
import AlertWithTitle from './Controls/AlertWithTitle';
import { APICall, WriteToActivityLog } from '../global/Api';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AutoLagoutTimer from '../global/IdleMonitor';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import TemplateUpdDlg from '../dialogs/TemplateUpdDlg';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const Templates = () => {

    const location = useLocation();
    let navigate = useNavigate();
    const queryClient = new QueryClient();
    const dispatch = useDispatch();
    const tableInstanceRef = useRef(null);
    const selTempltId = useRef(0);
    const selTempltNm = useRef('');

    const [templateSts, setTemplateSts] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showImportTemplateDlg, setShowImportTemplateDlg] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState('');
    const [templateErrorMsg, setTemplateErrorMsg] = useState('');
    const [templateStsErrorMsg, setTemplateStsErrorMsg] = useState('');
    const [inProgressCount, setInProgressCount] = useState(0);
    const [publishedCount, setPublishedCount] = useState(0);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    const [moreMnuAnchorEl, setMoreMnuAnchorEl] = useState(null);
    const openMoreMenu = Boolean(moreMnuAnchorEl);
    
    //const [isOuterLoading, setIsOuterLoading] = useState(false);

    const [disCreateTmpltBtn, setDisCreateTmpltBtn] = useState(true);
    const [disPublishTmpltBtn, setDisPublishTmpltBtn] = useState(true);
    const [disDeleteTmpltBtn, setDisDeleteTmpltBtn] = useState(true);
    const [disEditTmpltBtn, setDisEditTmpltBtn] = useState(true);
    const [disCopyTmpltBtn, setDisCopyTmpltBtn] = useState(true);
    const [disRefreshTmpltBtn, setDisRefreshTmpltBtn] = useState(true);
    const [disImportTmpltBtn, setDisImportTmpltBtn] = useState(true);

    let sessions = useSelector((state) => state.sessionMgmnt);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openPubCnfrmDlg, setOpenPubCnfrmDlg] = useState(false);
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [openRefershTemplateCnfrmDlg, setOpenRefershTemplateCnfrmDlg] = useState(false);
    const [templateCount, setTemplateCount] = useState(0);
    const [openCopyCnfrmDlg, setOpenCopyCnfrmDlg] = useState(false);
    const [openExportCnfrmDlg, setExportCnfrmDlg] = useState(false);

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');

    const cnfrmTemplateRefreshDlgHdr = useRef('');
    const cnfrmTemplateRefreshDlgBLine1 = useRef('');

    const cnfrmDelDlgHdr = useRef('');
    const cnfrmDelDlgBLine1 = useRef('');
    const cnfrmDelDlgBLine2 = useRef('');
    const cnfrmDelDlgBLine3 = useRef('');
    const cnfrmDelDlgBLine4 = useRef('');

    const cnfrmPubDlgHdr = useRef('');
    const cnfrmPubDlgBLine1 = useRef('');
    const cnfrmPubDlgBLine2 = useRef('');
    const cnfrmPubDlgBLine3 = useRef('');
    const cnfrmPubDlgBLine4 = useRef('');
    const newEnrolStatus = useRef(0);
    //const [publishedCount, setPublishedCount] = useState(0);
    //const [notPublishedCount, setNotPublishedCount] = useState(0);
    const [expanded, setExpanded] = useState(true);

    const tickDtNewSrtMnuDisp = useRef('block');
    const tickDtOldSrtMnuDisp = useRef('none');
    const tickTmpltNameSrtMnuDisp = useRef('none');
    const tickTmpltDescSrtMnuDisp = useRef('none');
    const tickTmpltOwnrSrtMnuDisp = useRef('none');
    const tickTmpltStsSrtMnuDisp = useRef('none');

    const cnfrmCopyDlgHdr = useRef('');
    const cnfrmCopyDlgBLine1 = useRef('');
    const cnfrmCopyDlgBLine2 = useRef('');
    const cnfrmCopyDlgBLine3 = useRef('');
    const cnfrmCopyDlgBLine4 = useRef('');

    const sortMenuAction = useRef(TemplateSortAction.Newest);

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleMoreMenuClick = (event) => {
        setMoreMnuAnchorEl(event.currentTarget);
    };
    const handleMoreMenuClose = () => {
        setMoreMnuAnchorEl(null);
    };

    const handlePubCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenPubCnfrmDlg(false);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_PUBLISH_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                TemplateId: selTempltId.current,
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { TemplateId: selTempltId.current } }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.Template, "Template published. Temlate name '" + selTempltNm.current + "'", userId, sessionId);
                    setSuccessMsgTitle("Template published");
                    setSuccessMsg('Template named as \'' + selTempltNm.current + '\' published successfully');
                    setRowSelection({});
                    setDisPublishTmpltBtn(true);
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const handleRefTemplateCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenRefershTemplateCnfrmDlg(false);
        setErrorMsg(null);
        setSuccessMsg(null);
        setSuccessMsgTitle(null);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_REFRESH_TEMPLATE_GWES_DATA,
                process.env.REACT_APP_BASE_URL,
            );

            //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_TEMPLATE;
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { TemplateId: selTempltId.current } }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                    setIsDataLoading(true);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.Template, "Template core data has been refreshed.", userId, sessionId);
                    setSuccessMsgTitle("Template refreshed");
                    setSuccessMsg('Template core data has been refreshed successfully');

                    setIsDataLoading(true);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const handleDelCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenDelCnfrmDlg(false);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setErrorMsg('');
            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                TemplateId: selTempltId.current,
            };

            //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_TEMPLATE;
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { TemplateId: selTempltId.current } }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.Template, "Template deleted. Temlate name '" + selTempltNm.current + "'", userId, sessionId);
                    setSuccessMsgTitle("Template deleted");
                    setSuccessMsg('Template named as \'' + selTempltNm.current + '\' deleted successfully');
                    setRowSelection({});
                    setDisPublishTmpltBtn(true);
                    setDisDeleteTmpltBtn(true);
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const handleCopyCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenCopyCnfrmDlg(false);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_COPY_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                TemplateId: selTempltId.current,
            };


            //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_TEMPLATE;
            // let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { SourceTemplateId: selTempltId.current } }, sessions, dispatch);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setIsDataLoading(false);
                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.Template, "Template copied. Temlate name '" + selTempltNm.current + "'", userId, sessionId);
                    setSuccessMsgTitle("Copy Template");
                    setSuccessMsg('The copy of \'' + selTempltNm.current + '\' template created successfully');
                    setRowSelection({});
                    setDisPublishTmpltBtn(true);
                    setDisDeleteTmpltBtn(true);
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const handleExportCnfrmDlgClose = async (nvalue, svalue) => {

        try {
            setMoreMnuAnchorEl(null);
            setIsDataLoading(true);

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            let fileName = selTempltNm.current + '.json';

            const apiUrl = new URL(
                process.env.REACT_APP_EXPORT_TEMPLATE_JSON,
                process.env.REACT_APP_BASE_URL,
            );
            
            apiUrl.searchParams.set('templateId', `${parseInt(selTempltId.current)}`);

            await GetAttachedFile(apiUrl.href, fileName, userId, sessionId);

            await WriteToActivityLog(ActivityLogEvent.Template, "Template Export. Source Temlate name '" + selTempltNm.current + "' and file name '" + fileName + "'", userId, sessionId);
            setSuccessMsgTitle("Export Template");
            setSuccessMsg('\'' + selTempltNm.current + '\' template exported successfully to ' + fileName);
            setRowSelection({});

        }
        catch (ex) {
            //setErrorMsg(ErrorMessages.UnknownError);
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            setExportCnfrmDlg(false);
            setIsDataLoading(false);
        }
    }

    const handleCopyClick = (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmCopyDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmCopyDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmCopyDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }
        cnfrmCopyDlgHdr.current = 'Are you sure you want to create a copy of the following template?';
        setOpenCopyCnfrmDlg(true);
    };

    const handleExportClick = (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmCopyDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmCopyDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmCopyDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }
        cnfrmCopyDlgHdr.current = 'Are you sure you want to export the following template?';
        setExportCnfrmDlg(true);
    };

    const handleImportClick = (table) => {
        setMoreMnuAnchorEl(null);
        setShowImportTemplateDlg(true);
    };

    const closeImportTemplateDlg = () => {
        //event.preventDefault();
        setShowImportTemplateDlg(false);
    }

    const columns = [
        {
            accessorFn: (row) => row.TemplateName,
            header: 'Template Name',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: 1,
                    //borderTop: '1px #BBBFBF solid',
                    borderBottom: 1,
                    borderColor: 'divider',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 0.5,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderLeft: 1,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    //letterSpacing: '1px',
                    pt: 1, pb: 1,
                }),
            }),
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 270
        },
        {
            accessorFn: (row) => row.TemplateDesc,
            header: 'Template Description',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 280
        },
        {
            accessorFn: (row) => row.TemplateOwner,
            header: 'Template Owner',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorFn: (row) => row.TemplateStatus,
            header: 'Status',
            size: 180
        },
        {
            accessorFn: (row) => row.LastModifiedUser,
            header: 'Last Modified By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorFn: (row) => row.LastModifiedOn,
            header: 'Last Modified',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [globalFilter, setGlobalFilter] = useState();

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', globalFilter],
            queryFn: async ({ pageParam = 0 }) => {

                setTemplateErrorMsg(ErrorMessages.UnknownError);

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_RESTRICTED_TEMPLATES,
                    process.env.REACT_APP_BASE_URL,
                );
                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);
                apiUrl.searchParams.set('globalFilter', globalFilter ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    let error = IsError(response);
                    if (error != 0) {
                        setErrorMsg(response.Errors[0].Message);
                    }

                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [globalFilter]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canAdd = IsInRole(AOBSystemProcess.TemplateMaintenance, userId, sessionId) || isAdmin;
        if (canAdd) {
            setDisCreateTmpltBtn(false);
            setDisRefreshTmpltBtn(false);
            setDisImportTmpltBtn(false);
        }
        else {
            setDisCreateTmpltBtn(true);
            setDisRefreshTmpltBtn(true);
            setDisImportTmpltBtn(true);
        }

        loadTemplateStatusSummary();
    }, []);

    const loadTemplateStatusSummary = async () => {

        let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_GET_TEMPLATE_STASTUS_SUMM,
            baseUrl
        );
        try {

            await WriteToActivityLog(ActivityLogEvent.Template, "Template list viewed.", userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setInProgressCount(response.data?.[0]?.InProgressCount ?? 0);
                setPublishedCount(response.data?.[0]?.PublishedCount ?? 0);

                //let count = 0;
                //if (response.data) {
                //    for (const x of response.data) {
                //        count += x.TemplateCount;
                //    }
                //}
                //setTemplateCount(count);
                //setTemplateSts(response.data);
            }
        }
        catch (err) {
            //setTemplateStsErrorMsg("ERROR while getting template information");
        }
        finally {
            //setIsDataLoading(false);
        }
    };

    const handleCreateNewUser = () => {

    };
    const handleRemoveUsers = () => {
    };

    const [rowSelection, setRowSelection] = useState({});

    const handleChange = (event) => {
        setRowSelection('500', true);
    };

    const HandleRowClick = async (row) => {

        tableInstanceRef.resetRowSelection = true;
        selTempltId.current = row.original.TemplateId;
        selTempltNm.current = row.original.TemplateName;

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);

        if (row.original.TemplateStatusID == 34)
            setDisPublishTmpltBtn(true);
        else
            setDisPublishTmpltBtn(false);

        //let userID = GetLoggedInUserId();

        let canDelete = IsInRole(AOBSystemProcess.DeleteTemplate, userId, sessionId) || isAdmin;
        if (parseInt(row.original.CrtUsrId) == parseInt(userId) || canDelete)
            setDisDeleteTmpltBtn(false);
        else
            setDisDeleteTmpltBtn(true);

        let canEdit = IsInRole(AOBSystemProcess.TemplateMaintenance, userId, sessionId) || isAdmin;
        if (parseInt(row.original.CrtUsrId) == parseInt(userId) || canEdit) {
            setDisEditTmpltBtn(false);
            setDisCopyTmpltBtn(false);
        }
        else {
            setDisEditTmpltBtn(true);
            setDisCopyTmpltBtn(true);
        }

        let canPublish = IsInRole(AOBSystemProcess.PublishTemplate, userId, sessionId) || isAdmin;
        if (parseInt(row.original.CrtUsrId) == parseInt(userId) || canPublish) {
            setDisPublishTmpltBtn(false);
        }
        else {
            setDisPublishTmpltBtn(true);
        }
    }

    const handleDtNewSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.Newest;
        refetch();

        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Date: Newest on top'", userId, sessionId);

        SetSortMenuTick(TemplateSortAction.Newest);
    }
    const handleDtOldSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.Oldest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Date: Oldest on top'", userId, sessionId);

        SetSortMenuTick(TemplateSortAction.Oldest);
    }
    const handleTmpltNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Template name'", userId, sessionId);

        SetSortMenuTick(TemplateSortAction.TemplateName);
    }
    const handleTmpltDescSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateDesc;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Template description'", userId, sessionId);

        SetSortMenuTick(TemplateSortAction.TemplateDesc);
    }
    const handleTmpltOwnrSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateOwner;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Template owner'", userId, sessionId);

        SetSortMenuTick(TemplateSortAction.TemplateOwner);
    }
    const handleTmpltStatusSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateStatus;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Status'", userId, sessionId);
        SetSortMenuTick(TemplateSortAction.TemplateStatus);
    }
    function SetSortMenuTick(item) {
        tickDtNewSrtMnuDisp.current = 'none';
        tickDtOldSrtMnuDisp.current = 'none';
        tickTmpltNameSrtMnuDisp.current = 'none';
        tickTmpltDescSrtMnuDisp.current = 'none';
        tickTmpltOwnrSrtMnuDisp.current = 'none';
        tickTmpltStsSrtMnuDisp.current = 'none';

        if (item == TemplateSortAction.Newest)
            tickDtNewSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.Oldest)
            tickDtOldSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateName)
            tickTmpltNameSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateDesc)
            tickTmpltDescSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateOwner)
            tickTmpltOwnrSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateStatus)
            tickTmpltStsSrtMnuDisp.current = 'block';
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        protein,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Published', 159, 6.0, 24, 4.0),
        createData('Not published', 237, 9.0, 37, 4.3),
    ];

    const handleEditClick = (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            navigate('/TemplateDesigner', { state: { templateid: parseInt(selTempltId.current), userId: userId, sessionId: sessionId }, replace: true });
        }
    };
    const handlePublishClick = (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmPubDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmPubDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmPubDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }

        cnfrmPubDlgHdr.current = 'Are you sure you want to publish the following template?';
        setOpenPubCnfrmDlg(true);
    };
    const handleDeleteClick = (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmDelDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmDelDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmDelDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }
        cnfrmDelDlgHdr.current = 'Are you sure you want to delete the following template?';
        setOpenDelCnfrmDlg(true);
    };
    const handleRefershTemplateClick = () => {
        cnfrmTemplateRefreshDlgHdr.current = 'Are you sure you want to refresh the template core data from GWES?';
        cnfrmTemplateRefreshDlgBLine1.current = '';
        setOpenRefershTemplateCnfrmDlg(true);
    };
    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };
    const NewTemplateClick = (event) => {
        //event.preventDefault();
        navigate('/TemplateDesigner', { state: { templateid: -1, userId: userId, sessionId: sessionId }, replace: true });
    };
    const RefershTemplateDesignerClick = (event) => {
        //event.preventDefault();
        //handleRefershTemplateClick();
        navigate('/TemplateDesignerRefresh', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };
    const GWESControlsClick = (event) => {
        //event.preventDefault();
        //handleRefershTemplateClick();
        navigate('/GWESControls', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };
    const RefershTemplateClick = (event) => {
        //event.preventDefault();
        //handleRefershTemplateClick();
        navigate('/RefreshTemplate', { state: { templateid: parseInt(selTempltId.current), templateNm: selTempltNm.current, userId: userId, sessionId: sessionId }, replace: true });
    };

    const onJsonChangeHandle = async () => {
        setShowImportTemplateDlg(false);

        try {
            //setSuccessMsgTitle("Template published");
            //setSuccessMsg('Template named as \'' + selTempltNm.current + '\' published successfully');
            //setRowSelection({});
            //setDisPublishTmpltBtn(true);
            //refetch();



            setSortMnuAnchorEl(null);
            sortMenuAction.current = TemplateSortAction.Newest;
            refetch();

            await WriteToActivityLog(ActivityLogEvent.Template, "Sorted template list by 'Date: Newest on top'", userId, sessionId);

            SetSortMenuTick(TemplateSortAction.Newest);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    }

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{ background: '#4C4C4C' }}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" sx={{ pt: 0, pb: 5 }}>
                        <Grid item md={12} xs={12} sx={{}}>
                            <Grid item container xs={12} md={12}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 0, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                        <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Account Opening Form Templates</Typography>
                                    </Box>
                                    <Box sx={{ }}>
                                        <Divider variant="fullWidth" component="div" />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box sx={{ mt: 0, pt: 3, pb: 2, ml: 6.5, mr: 0, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                        <Stack direction="column" sx={{ width: '99%' }}>
                                            <Typography sx={{ fontWeight: 400, borderRadius: '2px' }} variant="body1" color="text.primary">
                                                Create & manage all form templates in the system which are used as application form for opening an account.
                                                You can custimize existing forms or add new forms as per your needs.
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ display: 'flex', pb: 1, pt: 3, ml: 0, mr: 7, pr: 0, justifyContent: 'flex-end', alignItems: 'flex-start', borderBottom: '0px solid #d5dbdb' }}>
                                        <Box sx={{ border: 1, borderColor: 'divider', p: 1, pl: 2, pr: 2, pt: 2, pb: 2, borderRadius: '4px' }}>
                                            <Stack direction="column" spacing={2}>
                                                <Typography variant="body2" color="text.blueText" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 400, pb: 0, pr: 2, letterSpacing: '0px' }} > All Templates Status</Typography>
                                                <Stack direction="row" spacing={1}>
                                                    <Tooltip arrow title={publishedCount + ' Published'}>
                                                        <span>
                                                            <Avatar variant="square" sx={{ bgcolor: '#42A5F5', height: 22, width: 40, m: 0.1, opacity: 0.8, border: '1px solid #000' }}>
                                                                <Typography className="" variant="body1" sx={{ fontSize: '10px', fontWeight: 700 }}>
                                                                    {publishedCount > 99 ? publishedCount.toString().substring(0, 2) + '..' : publishedCount}
                                                                </Typography>
                                                            </Avatar>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title={inProgressCount + ' In Progress'}>
                                                        <span>
                                                            <Avatar variant="square" sx={{ bgcolor: '#9e9e9e', height: 22, width: 40, m: 0.1, opacity: 0.8, border: '1px solid #000' }}>
                                                                <Typography className="" variant="body1" sx={{ fontSize: '10px', fontWeight: 700 }}>
                                                                    {inProgressCount > 99 ? inProgressCount.toString().substring(0, 2) + '..' : inProgressCount}
                                                                </Typography>
                                                            </Avatar>
                                                        </span>
                                                    </Tooltip>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ ml: 6.5, mr: 6.5 }}>
                                <Paper elevation={0} sx={{}}>
                                    <AlertWithTitle Message={successMsg} variant="body3" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                </Paper>
                                <Paper elevation={0} sx={{}}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Paper>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" sx={{ ml: 6.5, mr: 6.5 }} />
                            </Box>
                            <Box sx={{ borderTop: '0px solid #898F94', mt: 1, ml: 6.5, mr: 6.5, pt: 1 }}>
                                <MaterialReactTable sx={{}}
                                    columns={columns}
                                    data={flatData}
                                    enableColumnActions={false}
                                    enableSorting={false}
                                    enablePagination={false}
                                    enableRowNumbers={false}
                                    enableRowVirtualization
                                    enableHiding={false}
                                    enableColumnOrdering={false}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => {
                                            setRowSelection((prev) => ({
                                                [row.id]: true,
                                            }));
                                            HandleRowClick(row);
                                        },
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                    positionActionsColumn="last"
                                    enableColumnResizing={false}
                                    enableDensityToggle={false}
                                    enableFullScreenToggle={false}
                                    muiTableBodyCellProps={({ column }) => ({
                                        sx: (theme) => ({
                                            color: 'text.primary',
                                            fontSize: theme.typography.body1,
                                            //borderLeft: '1px #BBBFBF solid',
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 0.5,
                                        }),
                                    })
                                    }
                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            color: 'text.primary',
                                            fontSize: theme.typography.body2,
                                            borderTop: 1,
                                            //borderLeft: '1px #BBBFBF solid',
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            fontWeight: 400,
                                            pl: 2, pr: 2,
                                            //letterSpacing: '1px',
                                            //borderRadius: '6px',
                                            pt: 1, pb: 1,
                                            //opacity: '0.8'
                                        }),
                                    }}

                                    muiTableContainerProps={{
                                        ref: tableContainerRef,
                                        sx: { maxHeight: '550px', minHeight: '400px' },
                                        onScroll: (
                                            event,
                                        ) => fetchMoreOnBottomReached(event.target),
                                    }}
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: ErrorMessages.UnknownError,
                                            }
                                            : undefined
                                    }
                                    onGlobalFilterChange={setGlobalFilter}
                                    renderBottomToolbarCustomActions={() => (
                                        <Box sx={{ mt: 0.5 }}>
                                            <Typography color="text.blueText" variant="body2" sx={{ pl: 0, fontWeight: 400 }}>
                                                {
                                                    !isError && data
                                                        ? totalFetched + ' of ' + totalDBRowCount + ' templates.'
                                                        : ''
                                                }
                                            </Typography>
                                        </Box>
                                    )}
                                    onRowSelectionChange={setRowSelection}
                                    state={{
                                        globalFilter,
                                        isLoading,
                                        showAlertBanner: isError,
                                        showProgressBars: isFetching,
                                        showSkeletons: isFetching,
                                        rowSelection,
                                    }}
                                    initialState={{
                                    }}
                                    muiTablePaperProps={{
                                        elevation: 0,
                                    }}
                                    rowVirtualizerProps={{ overscan: 2 }}
                                    localization={{
                                        noRecordsToDisplay: 'No template to display',
                                        selectedCountOfRowCountRowsSelected: '',
                                    }}
                                    renderTopToolbarCustomActions={({ table }) => {
                                        return (
                                            <Box sx={{
                                                display: 'flex', pl: 0, pt: 1, pb: 0, pr: 5, flexDirection: 'row',
                                            }}>
                                                {/*<TextSnippetOutlinedIcon sx={{ fontSize: 32, color: '#61676B', ml: 0 }} />*/}
                                                <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 1, fontWeight: 500, pl: 0.5, borderRadius: '4px', ml: 0, }}>Existing templates</Typography>
                                                <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2, }}>Sort</Typography>
                                                <IconButton sx={{ ml: 0, border: 0, borderColor: '#235ec9', p: 0, pr: 2, pt: 0 }} onClick={handleSortMnuClick}>
                                                    <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={sortMnuAnchorEl}
                                                    open={openSort}
                                                    onClose={handleSortMnuClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <MenuItem key={0} sx={{ mt: 1, ml: 1, mr: 1 }} onClick={(event) => handleDtNewSortMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickDtNewSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400, mr: 1 }}>Date: Newest on top</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleDtOldSortMenuClick(event)}>
                                                        <ListItemIcon fontSize="small">
                                                            <CheckIcon sx={{ display: tickDtOldSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" display="block" color="text.primary" sx={{ fontWeight: 400 }}>Date: Oldtest on top</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem key={2} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleTmpltNameSortMenuClick(event)}>
                                                        <ListItemIcon fontSize="small">
                                                            <CheckIcon sx={{ display: tickTmpltNameSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" display="block" color="text.primary" sx={{ fontWeight: 400 }}>Template name</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={3} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleTmpltDescSortMenuClick(event)}>
                                                        <ListItemIcon fontSize="small">
                                                            <CheckIcon sx={{ display: tickTmpltDescSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" display="block" color="text.primary" sx={{ fontWeight: 400 }}>Template description</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={4} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleTmpltOwnrSortMenuClick(event)}>
                                                        <ListItemIcon fontSize="small">
                                                            <CheckIcon sx={{ display: tickTmpltOwnrSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" display="block" color="text.primary" sx={{ fontWeight: 400 }}>Template owner</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem key={5} sx={{ mb: 1, ml: 1, mr: 1 }} onClick={(event) => handleTmpltStatusSortMenuClick(event)}>
                                                        <ListItemIcon fontSize="small">
                                                            <CheckIcon sx={{ display: tickTmpltStsSrtMnuDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body4" display="block" color="text.primary" sx={{ fontWeight: 400 }}>Status</Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                        );
                                    }}
                                    renderToolbarInternalActions={({ table }) => {
                                        return (
                                            <Box sx={{ pr: 1, display: 'flex', pt: 1, pb: 0, flexDirection: 'row', }}>
                                                <MRT_ToggleGlobalFilterButton table={table} color="#ccc" />
                                                <Tooltip arrow title="More options">
                                                    <span>
                                                        <IconButton size="small" sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 1, mb: 0 }} onClick={handleMoreMenuClick}>
                                                            <MoreVertIcon sx={{ fontSize: 26, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Menu
                                                    id="more-menu"
                                                    anchorEl={moreMnuAnchorEl}
                                                    open={openMoreMenu}
                                                    onClose={handleMoreMenuClose}
                                                >
                                                    <MenuItem sx={{ ml: 3, mt: 1.5, mb: 0 }} key={0} disabled={disCreateTmpltBtn} onClick={() => NewTemplateClick(table)}>
                                                        <ListItemIcon>
                                                            <PostAddOutlinedIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">New template</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={1} disabled={table.getSelectedRowModel().flatRows.length <= 0 || disEditTmpltBtn} onClick={() => handleEditClick(table)}>
                                                        <ListItemIcon>
                                                            <EditIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Edit template</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={2} disabled={table.getSelectedRowModel().flatRows.length <= 0 || disCopyTmpltBtn} onClick={() => handleCopyClick(table)}>
                                                        <ListItemIcon>
                                                            <FileCopyIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Copy template</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={3} disabled={table.getSelectedRowModel().flatRows.length <= 0 || disRefreshTmpltBtn} onClick={() => RefershTemplateClick(table)}>
                                                        <ListItemIcon>
                                                            <RefreshIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Refresh template</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={3} disabled={disRefreshTmpltBtn} onClick={() => RefershTemplateDesignerClick(table)}>
                                                        <ListItemIcon>
                                                            <RefreshIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Refresh template designer</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={3} onClick={() => GWESControlsClick(table)}>
                                                        <ListItemIcon>
                                                            <RefreshIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">GWES Controls</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 1.5, mb: 0 }} key={4} disabled={disPublishTmpltBtn} onClick={() => handlePublishClick(table)}>
                                                        <ListItemIcon>
                                                            <PublishIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Publish template</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={2} disabled={disImportTmpltBtn} onClick={() => handleImportClick(table)}>
                                                        <ListItemIcon>
                                                            <FileOpenIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Import template</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={2} disabled={table.getSelectedRowModel().flatRows.length <= 0 || disCopyTmpltBtn} onClick={() => handleExportClick(table)}>
                                                        <ListItemIcon>
                                                            <DownloadIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Export template</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 1.5 }} key={5} disabled={disDeleteTmpltBtn} onClick={() => handleDeleteClick(table)}>
                                                        <ListItemIcon>
                                                            <DeleteIcon sx={{ fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Delete template</Typography>
                                                    </MenuItem>                                                    
                                                </Menu>
                                            </Box>
                                        );
                                    }}
                                    tableInstanceRef={tableInstanceRef}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                    <AlertDialog
                        id="del-cnfrm-dlg"
                        keepMounted
                        open={openDelCnfrmDlg}
                        onClose={handleDelCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmDelDlgHdr.current}
                        bmsgline1={cnfrmDelDlgBLine1.current}
                        bmsgline2={cnfrmDelDlgBLine2.current}
                        bmsgline3={cnfrmDelDlgBLine3.current}
                        bmsgline4={cnfrmDelDlgBLine4.current}
                    />
                    <AlertDialog
                        id="copy-cnfrm-dlg"
                        keepMounted
                        open={openCopyCnfrmDlg}
                        onClose={handleCopyCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmCopyDlgHdr.current}
                        bmsgline1={cnfrmCopyDlgBLine1.current}
                        bmsgline2={cnfrmCopyDlgBLine2.current}
                        bmsgline3={cnfrmCopyDlgBLine3.current}
                        bmsgline4={cnfrmCopyDlgBLine4.current}
                    />
                    <AlertDialog
                        id="pub-cnfrm-dlg"
                        keepMounted
                        open={openPubCnfrmDlg}
                        onClose={handlePubCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmPubDlgHdr.current}
                        bmsgline1={cnfrmPubDlgBLine1.current}
                        bmsgline2={cnfrmPubDlgBLine2.current}
                        bmsgline3={cnfrmPubDlgBLine3.current}
                        bmsgline4={cnfrmPubDlgBLine4.current}
                    />
                    <AlertDialog
                        id="refresh-template-cnfrm-dlg"
                        keepMounted
                        nvalue={1}
                        open={openRefershTemplateCnfrmDlg}
                        onClose={handleRefTemplateCnfrmDlgClose}
                        alertheader={cnfrmTemplateRefreshDlgHdr.current}
                        bmsgline1={cnfrmTemplateRefreshDlgBLine1.current}
                    />
                    <AlertDialog
                        id="export-cnfrm-dlg"
                        keepMounted
                        open={openExportCnfrmDlg}
                        onClose={handleExportCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmCopyDlgHdr.current}
                        bmsgline1={cnfrmCopyDlgBLine1.current}
                        bmsgline2={cnfrmCopyDlgBLine2.current}
                        bmsgline3={cnfrmCopyDlgBLine3.current}
                        bmsgline4={cnfrmCopyDlgBLine4.current}
                    />
                    {
                        showImportTemplateDlg ?
                        <TemplateUpdDlg open={showImportTemplateDlg} onClose={closeImportTemplateDlg} onChange={onJsonChangeHandle} cntctid={userId} userId={userId} sessionId={sessionId} /> : null
                    }
                </Paper>
            </Container>
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const TemplateList = () => (
    <QueryClientProvider client={queryClient}>
        <Templates />
    </QueryClientProvider>
);

function TemplateListBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '0px solid #cdcfd2', pt: 2, pb: 2, background: '#F6F6F6', pl: 2 }}>
                <Typography variant="h6" color="#002d72" sx={{ lineHeight: 1, fontWeight: 500 }} >Application templates</Typography>
            </Box>

            {/*<Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>*/}
            {/*    <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 400, letterSpacing: '-1px' }} >Application templates</Typography>*/}
            {/*</Box>*/}

            {/*    <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>*/}
            {/*        <Typography variant="h4" color="text.primary" sx={{ lineHeight: 1, fontWeight: 300, letterSpacing: '-1px' }} >Application templates</Typography>*/}
            {/*    </Box>*/}
        </React.Fragment>
    );
}

export default TemplateList;