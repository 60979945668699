import * as $ from 'jquery';
import React, { Component, createRef, useRef, useEffect, useState, Card, CardMedia } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import { CallAPI, IsInRole } from '../global/APIUtils';
import BreadCumControl from './Controls/BreadcumControl';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import { MainNavMenu } from './MainNavMenu'
import { Box, Typography, Paper, Grid, Button, Divider, Stack, Tooltip, Link } from '@mui/material';
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from './Footer';
import './Modal.css';
import { NavMenu } from './NavMenu';
import './TemplateDesignPreview.css';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import pwdimg from '../assets/images/124567.jpg';
import Image from 'material-ui-image'
import { IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import { TemplateStatus, ActivityLogEvent, AOBSystemProcess } from '../common/AOBEnum';
import { goToTop } from '../global/Helpers'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import LensIcon from '@mui/icons-material/Lens';
import profimg from '../assets/images/profimg.jpg';
import { APICall, WriteToActivityLog } from '../global/Api';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AutoLagoutTimer from '../global/IdleMonitor';
import DispalyApplicationForm from '../global/ApplicationFormDisplay';
import { queue } from 'jquery';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteCoreDataControl from '../dialogs/DeleteCoreDataControl';

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

require('formBuilder/dist/form-render.min.js')

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const TemplateDesigner = (props) => {

    const fb = useRef(null);
    let formBuilder = useRef(null);
    //let sessions = useSelector((state) => state.sessionMgmnt);
    //const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const location = useLocation();
    const [openPreview, setOpenPreview] = React.useState(false);
    const [templateId, setTemplateId] = useState(location.state.templateid || -1);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [disPublishTmpltBtn, setDisPublishTmpltBtn] = useState(true);
    const [disRefTemplate, setDisRefTemplate] = useState(true);
    const [showDeleteCoreDataControlDlg, setShowDeleteCoreDataControlDlg] = useState(false);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    let navigate = useNavigate();

    const handlePreviewClose = () => {
        setOpenPreview(false);
    };

    const typeUserAttrs = {
        text: {
            //className: colStyleClass,
            maxlength: 20000,
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            onclick: {
                label: 'onClick',
                value: '',
                type: 'textarea'
            },
            onkeyup: {
                label: 'onKeyup',
                value: '',
                type: 'textarea'
            },
            onload: {
                label: 'onLoad',
                value: '',
                type: 'textarea'
            },
            onkeypress: {
                label: 'onkeypress',
                value: '',
                type: 'textarea'
            },
            onkeydown: {
                label: 'onkeydown',
                value: '',
                type: 'textarea'
            },
            oncut: {
                label: 'onCut',
                value: '',
                type: 'textarea'
            },
            onpaste: {
                label: 'onPaste',
                value: '',
                type: 'textarea'
            },
            ondrop: {
                label: 'onDrop',
                value: '',
                type: 'textarea'
            },
            oninput: {
                label: 'onInput',
                value: '',
                type: 'textarea'
            },
            autocomplete: {
                label: 'autocomplete',
                value: null,
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: null,
                type: 'checkbox'
            }
        },
        select: {
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            onchange: {
                label: 'onChange',
                value: '',
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        file: {
            //className: colStyleClass,
            disabledAttrs: [
                //'multiple',
                'subtype',
                'access'//,            
            ],
            'maxfilesize': {
                'label': 'Max File Size (MB)',
                'value': '4',
                'type': 'number'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        button: {
            //className: colStyleClass,
        },
        'checkbox-group': {
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            onclick: {
                label: 'onClick',
                value: '',
                type: 'textarea'
            },
            onchange: {
                label: 'onChange',
                value: '',
                type: 'textarea'
            },
            onload: {
                label: 'onLoad',
                value: '',
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        'radio-group': {
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            onchange: {
                label: 'onChange',
                value: '',
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        number: {
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            onkeypress: {
                label: 'onkeypress',
                value: '',
                type: 'textarea'
            },
            onkeydown: {
                label: 'onkeydown',
                value: '',
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        //header: {
        //    onkeypress: {
        //        label: 'onkeypress Event',
        //        value: '',
        //        type: 'textarea'
        //    },
        //},
        //"radio-group": {
        //    className: colStyleClass,
        //},
        textarea: {
            //className: colStyleClass,
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        date: {
            //className: colStyleClass,
            ExtMapping: {
                'label': 'Ext. Mapping',
                'value': '',
                'type': 'textbox'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        header: {
            adminonly: {
                label: 'Admin only',
                value: false,
                type: 'checkbox',
            },
            onblur: {
                label: 'onBlur',
                value: '',
                type: 'textarea'
            },
            onload: {
                label: 'onLoad',
                value: '',
                type: 'textarea'
            },
        }
    };
    const typeUserDisabledAttrs = {
        'file': [
            'subtype',
            'type',
            'access',
            'multiple'
        ]
    }

    const stickyControl = {
        stickyControls: {
            enable: true,
            offset: {
                top: 20,
                right: 20,
                left: 'auto'
            }
        }
    }

    const loadDesigner = async () => {

        try {

            setIsLoading(true);

            goToTop();

            const apiUrl = new URL(
                process.env.REACT_APP_GET_TEMPLATE_DESIGNER_DETAIL,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('templateId', templateId);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                await PopulateDesigner(response, templateId);
                await WriteToActivityLog(ActivityLogEvent.Template, "Opened template designer.", userId, sessionId);

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
        return;
    }

    const publishTemplate = async (templateStsId) => {

        let result = await saveData(templateStsId);

        if (result == true) {
            let templateNm = document.getElementById('txtTemplateName').value;

            await WriteToActivityLog(ActivityLogEvent.Template, "Template published. Temlate name '" + templateNm + "'", userId, sessionId);

            setSuccessMsgTitle('Template published');
            setSuccessMsg('The template named as \'' + templateNm + '\' published successfully');
        }

    }

    const saveTemplate = async (templateStsId) => {

        let result = await saveData(templateStsId);

        if (result == true) {
            let templateNm = document.getElementById('txtTemplateName').value;

            await WriteToActivityLog(ActivityLogEvent.Template, "Template saved. Temlate name '" + templateNm + "'", userId, sessionId);

            setSuccessMsgTitle('Template saved');
            setSuccessMsg('The template named as \'' + templateNm + '\' saved successfully');
        }

    }

    const refreshTemplate = async () => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        try {

            //const result = formBuilder.current.actions.save();

            const inputJson = {
                TemplateId: parseInt(templateId)
            };


            const apiUrl = new URL(
                process.env.REACT_APP_REFRESH_TEMPLATE_LOOKUP_DATA,
                process.env.REACT_APP_BASE_URL,
            );


            setIsLoading(true);
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return false;
                }
                await WriteToActivityLog(ActivityLogEvent.Template, "Template refreshed successfully.", userId, sessionId);

                //formBuilder.current.formData = null;
                loadDesigner();

                setSuccessMsgTitle('Template refreshed');
                setSuccessMsg('Template refreshed successfully');

                return true;
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }

    }

    const saveData = async (templateStsId) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        const templateNmContent = document.getElementById('txtTemplateName');
        const templateDescContent = document.getElementById('txtTemplateDesc');
        const isPhotoReqContent = document.getElementById('chkIsPhotoReq');
        const templateOnLoad = document.getElementById('txtFrmLoad').value;
        const templateOnSubmit = document.getElementById('txtFrmSubmit').value;

        let templateNm = templateNmContent.value;
        let templateDesc = templateDescContent.value;
        let isPhotoReq = isPhotoReqContent.checked;

        if (templateNm === '' || templateNm === null) {
            setErrorMsg('You need to give a name to this template');
            templateNmContent.focus();
            return false;
        }

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            const result = formBuilder.current.actions.save();
            const inputJson = {
                TemplateId: parseInt(templateId),
                TemplateName: templateNm,
                TemplateDesc: templateDesc,
                TemplateValue: formBuilder.current.formData,
                OnLoadHandler: templateOnLoad,
                OnSubmitHandler: templateOnSubmit,
                TemplateStatusID: templateStsId,
                IsPhotoReq: isPhotoReq
            };

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );


            setIsLoading(true);
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return false;
                }

                let templateID = response["data"][0].TemplateID;
                setTemplateId(templateID);

                return true;
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }

        return false;
    }

    const previewForm = async () => {

        let templateNm = document.getElementById('txtTemplateName').value;

        await WriteToActivityLog(ActivityLogEvent.Template, "Opened temlate preview. Template name: '" + templateNm + "'", userId, sessionId);

        setOpenPreview(true);
    }

    async function PopulateDesigner(response) {

        let hasSavedData = parseInt(templateId) > 0;

        let controlOrder = response.data[0].ControlOrder;
        let hideControl = response.data[0].HideControlList;
        let formData = hasSavedData ? response.data[0].TemplateDetails[0].TemplateValue : response.data[0].DefaultControls;
        let templateData = response.data[0].InputSet;
        let templateNm = hasSavedData ? response.data[0].TemplateDetails[0].TemplateName : '';
        let templateDesc = hasSavedData ? response.data[0].TemplateDetails[0].TemplateDesc : '';

        let onLoadHandler = hasSavedData ? response.data[0].TemplateDetails[0].OnLoadHandler : '';
        let onSubmitHandler = hasSavedData ? response.data[0].TemplateDetails[0].OnSubmitHandler : '';

        let isPhotoReq = hasSavedData ? response.data[0].TemplateDetails[0].IsPhotoReq : false;
        let isChecked = isPhotoReq ? 'checked' : '';

        let prependStr = `<div class="template-design">
                    <label class="template-name">Template Name<span class="required-asterisk" style="display:inline"> *</span></label>
                    <input type="text" id="txtTemplateName" required class="form-control" placeholder="Template Name" autoComplete="off" value="` + templateNm +
            `" /><label class="template-desc">Description</label>
                    <textarea id="txtTemplateDesc" class = "form-control" rows = "2" placeholder = "Description" autoComplete = "off" >` + templateDesc +
            `</textarea><div class="form-check" style="padding-top: 8px;"><input class="form-check-input" type="checkbox" value="" id="chkIsPhotoReq" ` + isChecked +
            `><label class="form-check-label" for="chkIsPhotoReq"> Requires Applicant Photo </label></div>
                    <label style="padding-top: 8px;padding-bottom: 0.3rem">On Load</label>
                    <textarea id="txtFrmLoad" class="form-control" rows = "2" placeholder="Write script here that is executed after the form load" autoComplete = "off" >` + onLoadHandler +
            `</textarea><label style="padding-top: 16px; padding-bottom: 0.3rem">On Submit</label>
                    <textarea id="txtFrmSubmit" class="form-control" rows = "2" placeholder="Write script here that is executed before form submit" autoComplete = "off" >` + onSubmitHandler +
            `</textarea></div>`;

        //let templateCntrlDivStrt = '<div class="template-design">'
        //let templateLabelCntrl = '<label class="template-name">Template Name<span class="required-asterisk" style="display:inline"> *</span></label>'
        //let templateNameCntrl = '<input type="text" id="txtTemplateName" required class="form-control" placeholder="Template Name" autoComplete="off" value="' + templateNm + '" />'
        //let templateDescLabelCntrl = '<label class="template-desc">Description</label>'
        //let templateDescCntrl = '<textarea id="txtTemplateDesc" class = "form-control" rows = "2" placeholder = "Description" autoComplete = "off" >' + templateDesc + '</textarea>'
        //let isPhotoReqCntrl = '<div class="form-check" style="padding-top: 8px;"><input class="form-check-input" type="checkbox" value="" id="chkIsPhotoReq" ' + isChecked + '><label class="form-check-label" for="chkIsPhotoReq"> Requires Applicant Photo </label></div>';
        //let templateCntrlDivEnd = '</div>'

        let options = {
            inputSets: templateData,
            controlOrder: controlOrder,
            disableFields: hideControl,
            prepend: prependStr,
            stickyControls: stickyControl.stickyControls,
            typeUserAttrs: typeUserAttrs,
            typeUserDisabledAttrs: typeUserDisabledAttrs,
            disableHTMLLabels: true,
            fieldRemoveWarn: true,
            scrollToFieldOnAdd: true,
            stickyControls: {
                enable: true
            },
            disabledAttrs: [
                'access'//,
            ],
            i18n: {
                location: process.env.REACT_APP_I18N_LANG
            },
            formData: formData,
            disabledActionButtons: ['clear', 'data', 'save'],
        };

        formBuilder.current = await $(fb.current).formBuilder(options);

    }
    useEffect(() => {

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canPublish = IsInRole(AOBSystemProcess.PublishTemplate, userId, sessionId) || isAdmin;

        if (canPublish) {
            setDisPublishTmpltBtn(false);
        }

        if (templateId > 0) {
            setDisRefTemplate(false);
        }

        loadDesigner();
    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleBackClick = () => {
        navigate('/TemplateList', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const GetTemplateName = () => {

        const templateNmContent = document.getElementById('txtTemplateName');

        let templateNm = templateNmContent && templateNmContent.value ? 'APPLICATION FORM TEMPLATE - ' + templateNmContent.value : 'APPLICATION FORM TEMPLATE';

        return templateNm;
    }

    const handleDeleteClick = (event) => {
        navigate('/DeleteCoreDataControl', { state: { userId: userId, sessionId: sessionId, templateid: templateId }, replace: true });
    };

    const closeDeleteCoreDataControlDlg = (msg) => {
        setShowDeleteCoreDataControlDlg(false);
        if (msg != null) {
            setSuccessMsg(msg);
            loadDesigner();
        }
        //refetch();
    }

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={0} sx={{ pt: 0, pb: 0, }}>
                        <Grid item xs={12} sx={{}}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0 }} >
                                    {(templateId !== null && templateId !== undefined && templateId !== -1) ? "Edit " : "New "}Template
                                </Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                        </Grid>
                        <Grid item xs={9} sx={{}}>
                            <Box sx={{ mt: 0, pt: 3, pb: 2, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Stack direction="column" sx={{ width: '99%' }}>
                                    <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                        Create Account Form by using this account form designer. Use it to design, create,
                                        and update account openning forms. This utility gives access to a drawing canvas
                                        upon which you construct your forms, a design toolbar from which you access
                                        controls and layout objects, and a properties view that you use to set
                                        attributes for each control or layout object.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box alignItems="center" sx={{ mr: 9, pb: 0, pt: 3.5, display: 'flex', }}>
                                <Stack direction="column" spacing={2} alignItems="center">
                                    <Box>
                                        <Stack direction="column" spacing={2}>
                                            <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none'}} onClick={() => previewForm()}>
                                                Preview
                                            </Button>
                                            <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none'}} onClick={() => saveTemplate(TemplateStatus.InProgress)}>
                                                Save
                                            </Button>
                                            <Button variant="contained" size="large" disabled={disPublishTmpltBtn} style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none'}} onClick={() => publishTemplate(TemplateStatus.Published)}>
                                                Publish
                                            </Button>
                                            {/*                               <Button variant="contained" size="large" disabled={disRefTemplate} style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={() => refreshTemplate()}>*/}
                                            {/*                                   REFERSH*/}
                                            {/*                               </Button>                                    */}
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/*<Box alignItems="center" sx={{ mr: 9, pb: 0, pt: 1, pl: 7, display: 'flex', justifyContent: 'flex-end' }}>*/}
                        {/*    <Stack direction="row" spacing={2} alignItems="center">*/}
                        {/*        <Box>*/}
                        {/*            <Stack direction="row" spacing={2} alignItems="center">*/}
                        {/*                <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={() => saveTemplate(TemplateStatus.InProgress)}>*/}
                        {/*                    SAVE*/}
                        {/*                </Button>*/}
                        {/*                <Button variant="contained" size="large" disabled={disPublishTmpltBtn} style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={() => publishTemplate(TemplateStatus.Published)}>*/}
                        {/*                    PUBLISH*/}
                        {/*                </Button>*/}
                        {/*                <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={() => previewForm()}>*/}
                        {/*                    PREVIEW*/}
                        {/*                </Button>*/}
                        {/*            </Stack>*/}
                        {/*        </Box>*/}
                        {/*    </Stack>*/}
                        {/*</Box>*/}
                        <Box sx={{ ml: { xs: 3, md: 6.5 }, mr: { xs: 3, md: 6.5 }, pt: 1 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} variant="body3" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{pb: 3}}>
                            <Divider variant="fullWidth" component="div" sx={{}} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Paper elevation={1} sx={{
                            mr: { xs: 3, md: 6.5 },
                            ml: { xs: 3, md: 6.5 },
                            pt: 0.5, pb: 4, mt: 0,
                            borderTop: '1px solid #D5D9D9', borderRadius: '6px', boxShadow: '0 1px 3px 0 #D5D9D9, 0 0 0 1px rgba(0,0,0,.05)'
                        }}>
                            <Box sx={{ borderBottom: '1px solid #BFC5C5', pl: 0, pr: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant="body1" color="text.blueText" sx={{ pt: 1, textTransform: 'none', fontWeight: 400, pl: 4.5, letterSpacing: '0px' }} >Template Designer</Typography>
                                <Typography variant="caption" color="text.primary" sx={{ textTransform: 'none', pt: 2, fontWeight: 400, pr: 1, pb: 1 }} >drag a control from control panel to the left design panel</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
                                <Stack direction="row">
                                    <Tooltip arrow title="Delete From Control Panel">
                                        <span>
                                            <IconButton size="small" sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 0, mb: 0 }} onClick={handleDeleteClick}>
                                                <DeleteForeverOutlinedIcon sx={{ fontSize: 22, color: 'Icon.main'  }} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none', textAlign: 'right', pt: 0.5, fontWeight: 600, pr: 5.5 }} >Control panel</Typography>
                                </Stack>
                            </Box>
                            <Box color="text.primary" sx={{ pl: 4.5, pt: 0.5, pr: 5, }} id="fb-editor" ref={fb} />
                        </Paper>
                    </Grid>
                    <Dialog
                        fullScreen
                        open={openPreview}
                        onClose={handlePreviewClose}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handlePreviewClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="body1" color="inherit" component="div">
                                    {GetTemplateName()}
                                </Typography>
                                <Button autoFocus color="inherit">
                                    Preview
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <DispalyApplicationForm data={formBuilder.current ? formBuilder.current.formData : null} />
                    </Dialog>
                    <Footer userId={userId} />
                </Paper>
            </Container>
            {showDeleteCoreDataControlDlg ?
                <DeleteCoreDataControl open={showDeleteCoreDataControlDlg} onClose={closeDeleteCoreDataControlDlg} userId={userId} sessionId={sessionId} />
                : null
            }
        </React.Fragment>
    );
}


const TemplateDesignerBreadcrumb = (props) => {

    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '0px solid #cdcfd2', pt: 2, pb: 2, background: '#F6F6F6', pl: 2 }}>
                <Typography variant="h6" color="#002d72" sx={{ lineHeight: 1, fontWeight: 500 }} >{props.text}</Typography>
            </Box>
            {/*    <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>*/}
            {/*        <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 400, letterSpacing: '-1px' }} >{props.text}</Typography>*/}
            {/*    </Box>*/}
        </React.Fragment>
    );
}

export default TemplateDesigner;