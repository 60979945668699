import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Divider, Typography, InputBase, IconButton, Toolbar, Grid, Stack, FormControl, Box, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPI, IsError } from '../global/APIUtils';
import AlertWithTitle from '../components/Controls/AlertWithTitle';
import AlertControl from '../components/Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import { Search } from 'react-bootstrap-icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { ConfirmDialog } from '../global/ConfirmDialog';
import AutoLagoutTimer from '../global/IdleMonitor';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import Footer from './Footer';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

const GWESControls = (props) => {

    const location = useLocation();
    let navigate = useNavigate();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
     const [searchText, setSearchText] = useState('');
    const [recordCount, setRecordCount] = React.useState(0);
 
    const [userId, setUserId] = useState(location.state ? location.state.userId : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');

    const loadForm = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_GWES_CONTROLS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('searchText', `${searchText}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {

                setData(response.data[0].sections);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadForm();
    }, []);

     const FindClick = async (e) => {

        e.preventDefault();

        loadForm();
    };

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.Name}
                    </TableCell>
                    <TableCell align="right">{row.subsections.length}</TableCell>
                    <TableCell align="right">{row.NoOfControls}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Section(s)
                                </Typography>
                                <Table size="small">
                                    {/*<TableHead>*/}
                                    {/*    <TableRow>*/}
                                    {/*        <TableCell align="right">Name</TableCell>*/}
                                    {/*        <TableCell align="right">Controls</TableCell>*/}
                                    {/*    </TableRow>*/}
                                    {/*</TableHead>*/}
                                    <TableBody>
                                        {row.subsections.map((ss) => (
                                            <SubRow key={ss.Name} row={ss} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    function SubRow(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.Name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.controls.length}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Controls
                                </Typography>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Label</TableCell>
                                            <TableCell>Control Name</TableCell>
                                            <TableCell>Control Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.controls.map((ctrl) => (
                                            <TableRow key={ctrl.ControlId}>
                                                <TableCell component="th" scope="row">
                                                    {ctrl.Caption}
                                                </TableCell>
                                                <TableCell>{ctrl.ControlId}</TableCell>
                                                <TableCell>
                                                    {
                                                        ctrl.ControlType == 'Checkbox' ? 'Checkbox Group' :
                                                            ctrl.ControlType == 'RadioButton' ? 'Radio Group' :
                                                                ctrl.ControlType == 'Select' ? 'Select' :
                                                                    ctrl.ControlType == 'TextArea' ? 'Text Area' :
                                                                        ctrl.ControlType == '' && ctrl.DataType == 'D' ? 'Date Field' :
                                                                            ctrl.ControlType == '' && ctrl.DataType == 'N' ? 'Number' :
                                                                                ctrl.ControlType == 'CheckboxList' ? 'Checkbox Group' : 'Text Field'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item>
                        <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6, mr: 6, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                            <Typography variant="h6" color="text.secondary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Controls in Account Enrollment of GWES</Typography>
                        </Box>
                        <Box sx={{ ml: 7, mr: 7, }}>
                            <Divider variant="fullWidth" component="div" sx={{}} />
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Box sx={{ mt: 0, pt: 3, pb: 2, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                            <Stack direction="column" sx={{ width: '99%' }}>
                                <Typography sx={{ fontWeight: 400, borderRadius: '2px' }} variant="body1" color="text.primary">
                                    Every controls used in account enrollment of GWES is displayed in the following table. This information is useful for designing templates.
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ pt: 1, ml: 6.5, mr: 6.5 }}>
                                <Paper elevation={0}
                                    sx={{ display: 'flex', alignItems: 'center', width: 550, border: 1, borderColor: '#BFC3C5', }}
                                >
                                    <InputBase className="form-control" sx={{ color: '#000', boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                        placeholder="Search control name, label, section" onChange={handleSearchTextChange}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => FindClick(e)}>
                                        <Search sx={{ color: '#333333' }} />
                                    </IconButton>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item sx={{pb: 10}}>
                        <Box sx={{ borderTop: '0px solid #898F94', mt: 1, ml: 6.5, mr: 6.5, pt: 1 }}>
                            <TableContainer component={Paper}>
                                <Table  style={{borderTop: '1px solid divider'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Section Name</TableCell>
                                            <TableCell align="right">Sub Sections</TableCell>
                                            <TableCell align="right">Controls</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data.map((row) => (
                                            <Row key={row.Name} row={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                         </Box>
                     </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default GWESControls;