 import React, { Component, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { DashBoard } from './components/DashBoard';
import { TemplateDesign } from './components/TemplateDesign';
import { TemplateDashBoard } from './components/TemplateDashBoard';
import { TemplateUI } from './components/TemplateUI';
//import { FileUploader } from './components/FileUploader';
//import { LookupDashBoard } from './components/LookupDashBoard';
//import { LookupConfig } from './components/LookupConfig';
import ChangePassword from './components/ChangePassword';
//import TemplateRestriction from './components/TemplateRestriction';
import AssignProcess from './components/AssignProcess';
//import ComposeMail from './components/ComposeMail';
import MessageCompose from './components/Message/MessageCompose';
//import { EnrollmentAddEditModal } from './components/EnrollmentAddEditModal';
import ManageUser from './components/Profile/ManageUser';
import ManageUserAdmin from './components/Profile/ManageUserAdmin';
import EnrollmentDocument from './components/Enrollment/EnrollmentDocument';
import ErrorInfoMain from './components/ErrorInfoMain';
// import SystemParameters1 from './components/SystemParameters1';
import ProcessManagement from './components/Role/RoleProcessManagement';
import { Logout } from './global/Logout';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import AutoLagoutTimer from './global/IdleMonitor';
import './assets/css/bootstrap.min.css';
import './assets/css/AOBStyle.css';
import { red } from '@mui/material/colors';
import { ServerAPICall } from './global/Api';
import { ErrorMessages } from './common/ErrorMessages';
import { GetOSPlatform, GetBrowserName, GetCountryOfUser } from './global/Helpers';
import { CssBaseline } from "@mui/material";
import { AppLogin } from './components/AppLogin';
import AppLogout from './components/AppLogout';
import OTPVerification from './components/OTPVerification';
import UserHome from './components/Home';
import ProspectDashboard from './components/ProspectDashboard';
import { ApplicationEnrollment } from './components/ApplicationEnrollment';
import ApplicationDashboard from './components/ApplicationDashboard';
import UserRegistration from './components/UserRegistration';
import ActivateRegistration from './components/ActivateRegistration';
import EmployeeDashboard from './components/EmployeeDashboard';
// const LazyUserRoleRoute = lazy(() => import('./lazyRoutes/LazyUserRoleRoute'));
import UserManagement from './components/UserManagement';
import User from './components/User';
import RoleManagement from './components/RoleManagement';
import RoleDefinition from './components/RoleDefinition';
// const LazyPasswordRoute = lazy(() => import('./lazyRoutes/LazyPasswordRoute'));
import ForgetPassword from './components/ForgetPassword';
import VerifyResetCode from './components/VerifyResetCode';
import ChangePasswordOption from './components/ChangePasswordOption';
import CreatePassword from './components/CreatePassword';
// const LazyTemplateRoute = lazy(() => import('./lazyRoutes/LazyTemplateRoute'));
import TemplateList from './components/TemplateList';
import TemplateDesigner from './components/TemplateDesigner';
import TemplateDesignerRefresh from './components/TemplateDesignerRefresh';
// const LazyEmailRoute = lazy(() => import('./lazyRoutes/LazyEmailRoute'));
import EmailMessages from './components/EmailMessages';
import EmailComposeMessage from './components/EmailComposeMessage';
import AuditTrailReport from './components/AuditTrailReport';
import UserProfileSetting from './components/UserProfileSetting';
import SystemConfig from './components/SystemConfig';
import ActivityLog from './components/ActivityLog';
import ExceptionDetails from './components/ExceptionDetails';
import DocuSign from './components/DocuSign';
//import SystemParameters1 from './components/SystemParameters1';
//import SystemParameters from './components/SystemParameters';
import DeleteCoreDataControl from './components/DeleteCoreDataControl';
import RefreshTemplate from './components/RefreshTemplate';
import { useThemeContext } from "./Theme/ThemeContextProvider";
import GWESControls from './components/GWESControls';

function AOBApp() {
    const { theme } = useThemeContext();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/' exact element={<AppLogin />} />
                        <Route path='/AppLogin' exact element={<AppLogin />} />
                        <Route path='/AppLogout' exact element={<AppLogout />} />
                        <Route path='/OTPVerification' exact element={<OTPVerification />} />
                        <Route path='/ActivateRegistration' exact element={<ActivateRegistration />} />
                        <Route path='/EmployeeDashboard' exact element={<EmployeeDashboard />} />
                        <Route path='/UserRegistration' exact element={<UserRegistration />} />
                        <Route path='/Enrollment' exact element={<ApplicationEnrollment />} />
                        <Route path='/UserProfileSetting' exact element={<UserProfileSetting />} />
                        <Route path='/ApplicationDashboard' exact element={<ApplicationDashboard />} />
                        {/* <Route path='/Password/*' element={<LazyPasswordRoute />} /> */}
                        <Route path="/ForgetPassword" element={<ForgetPassword />} />
                        <Route path="/VerifyResetCode" element={<VerifyResetCode />} />
                        <Route path="/ChangePasswordOption" element={<ChangePasswordOption />} />
                        <Route path="/CreatePassword" element={<CreatePassword />} />
                        {/* <Route path='/Users/*' exact element={<LazyUserRoleRoute />} /> */}
                        <Route path="/UserManagement" element={<UserManagement />} />
                        <Route path="/User" element={<User />} />
                        <Route path="/RoleManagement" element={<RoleManagement />} />
                        <Route path="/RoleDefinition" element={<RoleDefinition />} />
                        {/* <Route path='/Template/*' exact element={<LazyTemplateRoute />} /> */}
                        <Route path="/TemplateList" element={<TemplateList />} />
                        <Route path="/TemplateDesigner" element={<TemplateDesigner />} />
                        <Route path="/TemplateDesignerRefresh" element={<TemplateDesignerRefresh />} />
                        {/* <Route path='/Email/*' exact element={<LazyEmailRoute />} /> */}
                        <Route path="/EmailMessages" element={<EmailMessages />} />
                        <Route path="/EmailComposeMessage" element={<EmailComposeMessage />} />
                        <Route path='/DocuSign' exact element={<DocuSign />} />
                        <Route path='/UserHome' exact element={<UserHome />} />
                        <Route path='/SystemConfig' exact element={<SystemConfig />} />
                        <Route path='/AuditTrailReport' exact element={<AuditTrailReport />} />
                        <Route path='/ExceptionDetails' exact element={<ExceptionDetails />} />
                        <Route path='/ProspectDashboard' exact element={<ProspectDashboard />} />
                        {/*<Route path='/TemplateRestriction' exact element={<TemplateRestriction />} />*/}
                        <Route path='/ActivityLog' exact element={<ActivityLog />} />
                        <Route path='/DeleteCoreDataControl' exact element={<DeleteCoreDataControl />} />
                        <Route path='/RefreshTemplate' exact element={<RefreshTemplate />} />
                        <Route path='/GWESControls' exact element={<GWESControls />} />
                    </Routes>
                </Suspense>
            </Layout>

        </ThemeProvider>
    );
}

//class AOBApp extends Component {

//    static displayName = AOBApp.name;

//    state = {
//        aobTheme: {
//            typography: {
//                fontFamily: [
//                    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
//                ].join(','),
//                fontSize: 14,
//                h1: {
//                    fontSize: '3.75rem',
//                },
//                h2: {
//                    fontSize: '2.8rem',
//                },
//                h3: {
//                    fontSize: '2rem',
//                },
//                h4: {
//                    fontSize: '1.75rem',
//                },
//                h5: {
//                    fontSize: '1.57rem',
//                },
//                h6: {
//                    fontSize: '1.333rem',
//                },
//                subtitle1: {
//                    fontSize: '.9291rem',
//                },
//                subtitle2: {
//                    fontSize: '1.375rem',
//                },
//                body1: {
//                    fontSize: '18px',
//                },
//                body2: {
//                    fontSize: '16px',
//                    lineHeight: '1.5'
//                },
//                body3: {
//                    fontSize: '14px',
//                    lineHeight: '1.7'
//                },
//                body4: {
//                    fontSize: '15px',
//                    lineHeight: '1.5'
//                },
//                caption: {
//                    fontSize: '12px',
//                },
//                caption1: {
//                    fontSize: '11px',
//                },
//                caption2: {
//                    fontSize: '10px'
//                },
//            },
//            palette: {
//                text: {
//                    //    primary: '#06070E',
//                    //    secondary: '#000',
//                },
//                ochre: {
//                    main: '#000',
//                    light: '#E9DB5D',
//                    dark: '#A29415',
//                    contrastText: '#242105',
//                },
//            },
//            components: {
//                MuiButton: {
//                    styleOverrides: {
//                        root: ({ ownerState }) => ({
//                            ...(ownerState.variant === 'contained' && {
//                                backgroundColor: '#006fcf',
//                                color: '#fff',
//                                fontSize: '11px',
//                                letterSpacing: '0px',
//                            }),
//                        }),

//                    },
//                },
//            },
//        }
//    }

//    async InitApp() {
//        try {

//            let apiUrl = new URL(
//                process.env.REACT_APP_GET_APPL_THEME,
//                process.env.REACT_APP_BASE_URL,
//            );


//            let response = await ServerAPICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' });

//            if (response) {

//                this.setState(({ aobTheme }) => ({
//                    aobTheme: {
//                        ...aobTheme,
//                        palette: {
//                            text: {
//                                primary: response.data[0].PrimaryColor,
//                                secondary: response.data[0].SecondaryColor,
//                            }
//                        },
//                        components: {
//                            MuiButton: {
//                                styleOverrides: {
//                                    root: ({ ownerState }) => ({
//                                        ...(ownerState.variant === 'contained' && {
//                                            backgroundColor: response.data[0].BtnBGColor,
//                                            color: response.data[0].BtnColor,
//                                            '&:hover': {
//                                                backgroundColor: response.data[0].BtnBGColor,
//                                                color: response.data[0].BtnColor,
//                                            },
//                                            fontSize: '11px',
//                                            letterSpacing: '0px'
//                                        }),
//                                    }),

//                                },
//                            },
//                        },
//                    }
//                }));
//            }
//        }
//        catch (err) {
//            console.error(ErrorMessages.UnknownError);
//        }
//        finally {
//        }

//        //    let x = GetCountryOfUser();
//        //    let y = GetBrowserName();
//        //    let z = GetOSPlatform();
//    }

//    componentDidMount() {
//        this.InitApp();
//    }

//    render() {
//        const { aobTheme } = this.state;

//        return (
//            <ThemeProvider theme={createTheme(aobTheme)}>
//                <Layout>
//                    <Suspense fallback={<div>Loading...</div>}>
//                        <Routes>
//                            <Route path='/' exact element={<AppLogin />} />
//                            <Route path='/AppLogin' exact element={<AppLogin />} />
//                            <Route path='/AppLogout' exact element={<AppLogout />} />
//                            <Route path='/OTPVerification' exact element={<OTPVerification />} />
//                            <Route path='/ActivateRegistration' exact element={<ActivateRegistration />} />
//                            {/* <Route path='/SystemParameters' exact element={<SystemParameters />} /> }
//                            <Route path='/SystemParameters1' exact element={<SystemParameters1 />} />
//                            <Route path='/ProspectDashboard' exact element={<ProspectDashboard />} />
//                            {/*<Route path='/ProspectDashboard' exact element={<AutoLagoutTimer ComposedClass={ProspectDashboard}/>} />*/}
//                            {/*    <AutoLagoutTimer ComposedClass={ProspectDashboard} />*/}
//                            {/*</Route>*/}
//                            <Route path='/EmployeeDashboard' exact element={<EmployeeDashboard />} />
//                            <Route path='/UserRegistration' exact element={<UserRegistration />} />
//                            <Route path='/Enrollment' exact element={<ApplicationEnrollment />} />
//                            <Route path='/UserProfileSetting' exact element={<UserProfileSetting />} />
//                            <Route path='/ApplicationDashboard' exact element={<ApplicationDashboard />} />
//                            {/* <Route path='/Password/*' element={<LazyPasswordRoute />} /> */}
//                            <Route path="/ForgetPassword" element={<ForgetPassword />} />
//                            <Route path="/VerifyResetCode" element={<VerifyResetCode />} />
//                            <Route path="/ChangePasswordOption" element={<ChangePasswordOption />} />
//                            <Route path="/CreatePassword" element={<CreatePassword />} />
//                            {/* <Route path='/Users/*' exact element={<LazyUserRoleRoute />} /> */}
//                            <Route path="/UserManagement" element={<UserManagement />} />
//                            <Route path="/User" element={<User />} />
//                            <Route path="/RoleManagement" element={<RoleManagement />} />
//                            <Route path="/RoleDefinition" element={<RoleDefinition />} />
//                            {/* <Route path='/Template/*' exact element={<LazyTemplateRoute />} /> */}
//                            <Route path="/TemplateList" element={<TemplateList />} />
//                            <Route path="/TemplateDesigner" element={<TemplateDesigner />} />
//                            <Route path="/TemplateDesignerRefresh" element={<TemplateDesignerRefresh />} />
//                            {/* <Route path='/Email/*' exact element={<LazyEmailRoute />} /> */}
//                            <Route path="/EmailMessages" element={<EmailMessages />} />
//                            <Route path="/EmailComposeMessage" element={<EmailComposeMessage />} />
//                            <Route path='/DocuSign' exact element={<DocuSign />} />
//                            <Route path='/UserHome' exact element={<UserHome />} />
//                            <Route path='/SystemConfig' exact element={<SystemConfig />} />
//                            <Route path='/AuditTrailReport' exact element={<AuditTrailReport />} />
//                            <Route path='/ExceptionDetails' exact element={<ExceptionDetails />} />
//                            {/*<Route path='/TemplateRestriction' exact element={<TemplateRestriction />} />*/}
//                            <Route path='/ActivityLog' exact element={<ActivityLog />} />
//                            <Route path='/DeleteCoreDataControl' exact element={<DeleteCoreDataControl />} />
//                            <Route path='/RefreshTemplate' exact element={<RefreshTemplate />} />
//                        </Routes>
//                    </Suspense>
//                </Layout>
//            </ThemeProvider>
//        );
//    }
//}

export default AOBApp;
